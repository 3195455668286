<template lang="pug">
  .new-pupil-wrapper
    h1 Пригласить нового ученика

    form(@submit.prevent="savePupil")
      .form-group
        input.form-control(type="text"
                           placeholder="Фамилия"
                           v-model="pupil.surname"
                           :class="{ 'is-invalid': $v.pupil.surname.$invalid && $v.pupil.surname.$error }")

        .invalid-feedback Обязательное поле

      .form-group
        input.form-control(type="text"
                           placeholder="Имя"
                           v-model="pupil.name"
                           :class="{ 'is-invalid': $v.pupil.name.$invalid && $v.pupil.name.$error }")

        .invalid-feedback Обязательное поле

      .form-group
        input.form-control(type="text"
                           placeholder="Email"
                           v-model="pupil.email"
                           :class="{ 'is-invalid': $v.pupil.email.$invalid && $v.pupil.email.$error }")

        .invalid-feedback(v-if="!$v.pupil.email.email") Введите корректный email

      .form-group
        input.form-control(type="text"
                           placeholder="Телефон"
                           v-model="pupil.phone"
                           :class="{ 'is-invalid': $v.pupil.phone.$invalid && $v.pupil.phone.$error }")

        .invalid-feedback(v-if="!$v.pupil.phone.required") Обязательное поле

      button.btn.btn-outline-success(type="submit") Отправить приглашение
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      pupil: {
        name: '',
        surname: '',
        email: ''
      }
    }
  },
  methods: {
    ...mapActions({
      create: 'coachPupils/create'
    }),
    savePupil () {
      this.$v.$touch()

      if (this.$v.$invalid) { return }

      this.create({ pupil: this.pupil }).then(() => {
        this.$notify({
          title: 'Приглашение ученика',
          type: 'success',
          text: 'Приглашение успешно отправлено ученику'
        })

        this.$router.push({ name: 'pupils_index' })
      }).catch(() => {
        this.$notify({
          title: 'Приглашение ученика',
          type: 'error',
          text: 'Приглашение ученика не удалось'
        })

        this.$router.push({ name: 'pupils_index' })
      })
    }
  },
  validations: {
    pupil: {
      name: { required },
      surname: { required },
      email: { email },
      phone: { required }
    }
  }
}</script>
