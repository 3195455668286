































import nutritionProgram from './nutrition_program.vue'
import { currentPupilMixin } from '@/mixins/currentPupilMixin'
import { Component, Vue } from 'vue-property-decorator'
import { IPupil } from '@/interfaces/IPupil'

@Component({
  components: { nutritionProgram },
  mixins: [currentPupilMixin]
})

export default class PupilShow extends Vue {
  currentPupil!: IPupil

  created () {
    if (this.$route.name !== 'pupil_show') { return }

    setTimeout(() => {
      this.$router.push({ name: 'pupil_training_programs', params: { id: this.currentPupil.id.toString() } })
    }, 500)
  }

  optionLocalizedName (options: any, name: any) {
    if (!options) { return }

    const option = options.find((o: any) => o.name === name)
    if (!option) { return }

    return option.localized_name
  }

  generateResetLink (): void {
  }
}
