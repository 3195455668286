





































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import pupil from '@/views/coach/pupils/pupil.vue'
import InfiniteLoading from 'vue-infinite-loading'
import debounce from 'debounce'

@Component({
  components: { pupil, InfiniteLoading },
  methods: {
    ...mapActions({
      getPupils: 'coachPupils/index',
      nullifyPage: 'coachPupils/nullifyPage',
      nullifyPupils: 'coachPupils/nullifyPupils',
      createInviteLink: 'coach/createInviteLink'
    })
  },
  computed: {
    ...mapGetters({
      allPupils: 'coachPupils/all'
    })
  }
})

export default class PupilsIndex extends Vue {
  q: String = ''
  state: String = 'active'
  inviteLink: string = ''
  allPupils!: any
  getPupils!: any

  nullifyPage!: () => any
  nullifyPupils!: () => any
  createInviteLink!: () => any

  generateInviteLink () {
    this.createInviteLink().then((response: any) => {
      console.log(response)
      this.inviteLink = response.url
    })
  }

  loadPupils ($state: any) {
    this.getPupils({ q: this.q }).then((response: any) => {
      if (response.items.length) {
        $state.loaded()
      } else {
        $state.complete()
      }
    })
  }

  search = debounce(function (this: any, evt: any) {
    this.q = evt.target.value

    this.nullifyPage()
    this.nullifyPupils()
  }, 500)

  setSelected (state: string) {
    this.state = state
  }

  isSelected (state: string): Boolean {
    return this.state === state
  }

  copyLink () {
    navigator.clipboard.writeText(this.inviteLink)
  }

  get filteredPupils () {
    return this.allPupils.filter((pupil: any) => {
      if (this.isSelected('active')) {
        if (!pupil.is_archived) { return pupil }
      }

      if (this.isSelected('archived')) {
        if (pupil.is_archived) { return pupil }
      }
    })
  }
}
