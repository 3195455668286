<template lang="pug">
  .col-12.col-lg-4.col-md-6.col-sm-6.pupil
    .pupil__body
      router-link.pupil__name.col-9.offset-3.col-lg-9.offset-lg-3.col-sm-10.offset-sm-2.col-md-9.offset-md-3(:to="{ name: 'pupil_show', \
                         params: { id: pupil.id } }")
        | {{ pupil.full_name }}

      a.col-9.offset-3.col-lg-9.offset-lg-3.col-sm-10.offset-sm-2.col-md-9.offset-md-3(href='#' @click.prevent='setState("true")' v-if="!pupilCopy.is_archived") В архив
      a.col-9.offset-3.col-lg-9.offset-lg-3.col-sm-10.offset-sm-2.col-md-9.offset-md-3(href='#' @click.prevent='setState("false")' v-if="pupilCopy.is_archived") Из архива

      .pupil__image-with-actions.d-flex.flex-row.align-items-center
        .pupil__image.col-3.col-lg-3.col-sm-2.col-md-3
          img.card-img-top(:src="pupil.avatar_url_x200" v-if="pupil.avatar_url")
          img.card-img-top(src="/default.png" v-else)

        .pupil__information.col-9.col-lg-9.col-sm-10.col-md-9
          router-link.r-coach-btn.r-coach-btn__width-auto.mr-3(:to="{ name: 'pupil_training_programs', \
                                                                      params: { id: pupil.id }}")
            | Тренировки
          router-link.r-coach-btn.r-coach-btn__width-auto.mr-3(:to="{ name: 'pupil_nutrition_programs', \
                                                                      params: { id: pupil.id }}")
            | Питание
          font-awesome-icon.pupil__show-profile(icon="bars" @click="showProfile = !showProfile")
      .pupil__profile(v-show="showProfile")
        .pupil__profile-item(v-for="{ name, type, localized_name, options } in pupil.profile_structure"
                             v-if=" pupil.profile[name]")
          .pupil__profile-title {{ localized_name }}:
          .pupil__profile-value(v-if="type !== 'select'") {{ pupil.profile[name] }}
          .pupil__profile-value(v-if="type === 'select'") {{ optionLocalizedName(options, pupil.profile[name]) }}

        .pupil__contacts
          .pupil__contacts-item(v-if="pupil.phone")
            font-awesome-icon.pupil__contacts-icon(icon="phone")
            span.pupil__contacts-contact {{ pupil.phone }}

          .pupil__contacts-item(v-if="pupil.email")
            font-awesome-icon.pupil__contacts-icon(icon="envelope")
            span.pupil__contacts-contact {{ pupil.email }}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      showProfile: false,
      pupilCopy: {}
    }
  },
  mounted () {
    this.pupilCopy = Object.assign({}, this.pupil)
  },
  props: ['pupil'],
  methods: {
    ...mapActions({
      updatePupil: 'coachPupils/update'
    }),
    optionLocalizedName (options, name) {
      if (!options) { return }

      const option = options.find(o => o.name === name)
      if (!option) { return }

      return option.localized_name
    },
    setState (state) {
      const params = {
        user: {
          id: this.pupil.id,
          archived: state
        }
      }

      this.updatePupil(params).then((pupil) => {
        this.pupilCopy = pupil
      })
    }
  }
}
</script>
